import cartMiniFooter from './directives/сartMiniFooter.js';
import cartFullModern from './directives/cartFull.js'; //GlorySoft_011
//import cartMobileFullModern from './directives/cartMobileFull.js';//GlorySoft_032
import './controllers/cartFullController.js'; //GlorySoft_011
//import './controllers/cartMobileFullController.js';//GlorySoft_032
import pathToCartMiniTemplate from './templates/cart-mini.html';
import pathToCartMiniSidebarTemplate from './templates/cart-mini-sidebar.html';
import './styles/cart.scss';
const MODULE_NAME = 'cart';
angular.module(MODULE_NAME).config(["$provide", function ($provide) {
  $provide.decorator("cartMiniListDirective", ["$delegate", function ($delegate) {
    const directive = $delegate[0];
    directive.templateUrl = pathToCartMiniTemplate;
    return $delegate;
  }]);
}]).directive("cartMiniFooter", cartMiniFooter).directive("cartFullModern", cartFullModern) //GlorySoft_011
//.directive(`cartMobileFullModern`, cartMobileFullModern)//GlorySoft_032
.run(["cartService", "cartConfig", "sidebarsContainerService", "$translate", (cartService, cartConfig, sidebarsContainerService, $translate) => {
  cartService.addCallback(cartConfig.callbackNames.add, () => {
    sidebarsContainerService.open({
      contentId: "cart-mini",
      templateUrl: pathToCartMiniSidebarTemplate,
      title: $translate.instant('Js.Cart.Cart'),
      hideFooter: true,
      sidebarClass: "sidebar--cart"
    });
  }, "cartMiniListSidebar");
  cartService.addCallback(cartConfig.callbackNames.open, () => {
    sidebarsContainerService.open({
      contentId: "cart-mini",
      templateUrl: pathToCartMiniSidebarTemplate,
      title: $translate.instant('Js.Cart.Cart'),
      hideFooter: true,
      sidebarClass: "sidebar--cart"
    });
  });
}]);
export default MODULE_NAME;