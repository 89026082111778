//GlorySoft_011
export default function CartFullModern() {
  return {
    restrict: 'EA',
    scope: {
      photoWidth: '@'
    },
    controller: 'CartFullModernCtrl',
    controllerAs: 'cartFull',
    bindToController: true,
    replace: true,
    templateUrl: '/templates/sapphire/scripts/_partials/cart/templates/cart-full.html' //'/scripts/_partials/cart/templates/cart-full.html'
  };
}