import mobileDetailsTemplate from './mobileDetails.html';
import detailsTemplate from './details.html';
import itemsTemplate from './items.html';
//GlorySoft_016
function orderHistoryModernDirective() {
  return {
    restrict: 'A',
    scope: {
      mode: '=',
      onChangeView: '&',
      titleText: '@',
      isLp: '<?'
    },
    bindToController: true,
    controller: 'OrderHistoryModernCtrl',
    controllerAs: 'orderHistoryModern',
    replace: true,
    template: function (elem, attr) {
      if (attr.isMobile) {
        return '<div data-ng-switch="orderHistoryModern.mode"><div data-order-history-items-modern data-ng-switch-when="all"></div><div data-order-history-details-modern data-is-mobile="true" data-is-lp="orderHistoryModern.isLp" data-ng-switch-when="details"></div></div>';
      } else {
        return '<div><div data-ng-if="orderHistoryModern.isGetOrdersData"><div data-ng-switch="orderHistoryModern.mode"><div data-order-history-items-modern data-ng-switch-when="all"></div><div data-order-history-details-modern data-is-lp="orderHistoryModern.isLp" data-ng-switch-when="details"></div></div></div><div class="order-history__spinner svg-spinner" data-ng-if="!orderHistoryModern.isGetOrdersData"></div></div>';
      }
    }
  };
}
function orderHistoryItemsModernDirective() {
  return {
    require: '^orderHistoryModern',
    restrict: 'A',
    scope: {
      isLp: '<?'
    },
    replace: true,
    templateUrl: itemsTemplate,
    //'/scripts/_partials/order/templates/items.html',
    link: function (scope, element, attrs, ctrl) {
      scope.parentScope = ctrl;
    }
  };
}
function orderHistoryDetailsModernDirective() {
  return {
    require: '^orderHistoryModern',
    restrict: 'A',
    scope: {
      isLp: '<?'
    },
    replace: true,
    templateUrl: function (elem, attr) {
      if (attr.isMobile) {
        return mobileDetailsTemplate;
      } else {
        return detailsTemplate;
      }
    },
    link: function (scope, element, attrs, ctrl) {
      scope.parentScope = ctrl;
    }
  };
}
function orderHistoryPayModernDirective() {
  return {
    //require: '^orderHistoryModern',
    restrict: 'AE',
    scope: {
      orderCode: '<?',
      payCode: '<?',
      // orderId: '<?',
      paymentMethodId: '<?',
      pageWithPaymentButton: '@',
      validationDisabled: '<?'
    },
    bindToController: true,
    controller: 'OrderPayCtrl',
    controllerAs: 'orderPay',
    template: function (elem, attr) {
      return "<div data-ng-include=\"'/checkout/getorderpay?ordercode='+orderPay.orderCode+'&orderid='+orderPay.orderId+'&paymentmethodid='+orderPay.paymentMethodId+'&pagewithpaymentbutton='+orderPay.pageWithPaymentButton+'&validationDisabled='+orderPay.validationDisabled+'&rnd='+orderPay.rnd\"></div>";
      //return `<div data-ng-include="'/checkout/billing?code='+orderPay.Code+'hash='+orderPay.payCode"></div>`;
    }
  };
}

export { orderHistoryModernDirective, orderHistoryItemsModernDirective, orderHistoryDetailsModernDirective, orderHistoryPayModernDirective };