import { PubSub } from "../../../../../../scripts/_common/PubSub/PubSub.js";
;
(function (ng) {
  'use strict';

  var CartFullModernCtrl /*GlorySoft_011*/ = function ($rootScope, cartService, moduleService, /*GlorySoft_011*/$q, $http, wishlistService) {
    var ctrl = this,
      //GlorySoft_011
      isRequestProcess = false,
      isInitilaze = false,
      deferes = [],
      cart = {};
    ctrl.$onInit = function () {
      console.log('CartFullModernCtrl');
      ctrl /*GlorySoft_011 cartService*/.getData().then(function (data) {
        ctrl.cartData = data;
      });
    };
    ctrl.updateAmount = function (value, itemId) {
      var item = {
        Key: itemId,
        Value: value
      };
      cartService.updateAmount([item]).then(function ( /*GlorySoft_011*/data) {
        moduleService.update('fullcartmessage');
        ctrl.cartData = data; //GlorySoft_011
      });
    };

    ctrl.remove = function (shoppingCartItemId) {
      cartService.removeItem(shoppingCartItemId).then(function (result) {
        moduleService.update('fullcartmessage');
        PubSub.publish("cart.remove", result.offerId);
        ctrl.getData(false).then(function (data) {
          //GlorySoft_011
          ctrl.cartData = data;
        });
      });
    };
    ctrl.clear = function () {
      cartService.clear().then(function () {
        moduleService.update('fullcartmessage');
        PubSub.publish("cart.clear");
        ctrl.getData(false).then(function (data) {
          //GlorySoft_011
          ctrl.cartData = data;
        });
      });
    };
    ctrl.refresh = function () {
      return cartService.getData(false).then(function (data) {
        moduleService.update('fullcartmessage');
        ctrl.cartData = data;
      });
    };
    ctrl.getData = function (cache, queryParams) {
      //GlorySoft_011

      var defer = $q.defer();
      if (cache == null) {
        cache = true;
      }
      if (isInitilaze === true && cache === true) {
        defer.resolve(cart);
      } else {
        if (isRequestProcess === true) {
          deferes.push(defer);
        } else {
          isRequestProcess = true;
          deferes.push(defer);
          $http.post('/1cv8/cart/getCart', angular.extend({
            rnd: Math.random()
          }, queryParams || {})).then(function (response) {
            angular.extend(cart, response.data);
            isInitilaze = true;
            isRequestProcess = false;
            for (var i = deferes.length - 1; i >= 0; i--) {
              deferes[i].resolve(cart);
            }
            deferes.length = 0;
          });
        }
      }
      return defer.promise.then(function (result) {
        cartService.processCallback('get', result);
        return result;
      });
    };
    ctrl.wishlist = function (shoppingCartItemId, offerId, remove) {
      //GlorySoft_018
      console.log('wishlist');
      wishlistService.add(offerId /*, state*/).then(response => {
        if (remove) {
          ctrl.remove(shoppingCartItemId);
        }
      });
    };
  };
  angular.module('cart').controller('CartFullModernCtrl', CartFullModernCtrl); /*GlorySoft_011*/

  CartFullModernCtrl.$inject = ['$rootScope', 'cartService', 'moduleService', '$q', '$http', 'wishlistService']; /*GlorySoft_011*/
})(window.angular);